import React from 'react';
import Breadcumbs from '../../components/Breadcumbs';
import Footer from '../../components/Footer';
import ImageHeader from '../../components/ImageHeader';
import Navigationbar from '../../components/Navigationbar';
// import banner_3 from '../../images/banner_3.png';
import { Accordion, Table } from 'react-bootstrap';

const Rules = () => {
    const items = [{item:'Home',url:'/'}]
    const itemActive = 'Admissions / Admission Rules'

    return (
        <div>
            <Navigationbar></Navigationbar>
            <ImageHeader image={"/images/banner_3.png"}></ImageHeader>
            <Breadcumbs items={items} actives={itemActive}></Breadcumbs>
            <br></br>
            <br></br>
            <div className="content">
                <div className="section section-padding">
                    <div className="container">
                        <h2> Admission Criteria And Seats Allocation</h2>
                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>ALLOCATION OF SEATS</Accordion.Header>
                                <Accordion.Body>
                                    <Table bordered hover responsive>
                                        <thead style={{background:'#14062b',textAlign:'center'}}>
                                            <tr>
                                                <th colSpan='3' style={{color:'whitesmoke'}}>ADMISSION CATEGORIES</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr style={{textAlign:'center'}}>
                                                <td>MERIT</td>
                                                <td>SELF-FINANCE</td>
                                                <td>OVERSEAS</td>
                                            </tr>
                                            <tr style={{textAlign:'center'}}>
                                                <td>40 Seats</td>
                                                <td>07 Seats</td>
                                                <td>03 Seats</td>
                                            </tr>
                                            <tr style={{background:'whitesmoke'}}>
                                                <td colSpan='3'>
                                                    <p>
                                                        Interested candidates can apply under three admission categories 
                                                        for the admission in Doctor of Physiotherapy from all over the 
                                                        country but application form of those candidates will be considered 
                                                        and preferable for the admission whose parents are currently working 
                                                        abroad.
                                                        <p>
                                                            <strong>
                                                                NOTE: ALL THE SEATS WILL BE FILLED AS PER CATEGORY ON MERIT.
                                                            </strong>
                                                        </p>
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>ELIGIBILITY CRITERIA</Accordion.Header>
                                <Accordion.Body>
                                    <ol style={{marginLeft:'10px',fontSize:'14px'}}>
                                        <li>
                                            The Admission in Doctor of Physiotherapy will purely be on merit basis. 
                                            The candidates who have passed the higher Secondary School Certificate with Pre-Medical 
                                            subjects (Biology, Chemistry, and Physics) or O-Level, and have secured at least 60% 
                                            marks (B-Grade) in aggregate from any board of Higher Secondary Education in Pakistan 
                                            or any institution from Foreign Countries duly recognized by Inter-Board Committee 
                                            of Chairman (IBCC) are eligible to apply for Admission.
                                        </li>
                                        <li>
                                            The candidates who have appeared in the Entry test of MBBS / BDS for Admission session 
                                            2018 conducted by NTS can apply for DPT admission by providing the copy of result of 
                                            Entry Test.
                                        </li>
                                        <li>
                                            The candidate who have attempted the Entry test of MBBS / BDS for admission session 
                                            2018 will be given preference.
                                        </li>
                                        <li>Candidate should be permanent Resident of Pakistan.</li>
                                        <li>Medium of Instructions / teaching of course shall be English.</li>
                                    </ol>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>FORMULA FOR WORKING OUT THE OVERALL MERIT</Accordion.Header>
                                <Accordion.Body>
                                    <Table bordered hover responsive>
                                        <tbody>
                                            <tr>
                                                <td>a) Matric Science/ O-Level / Equivalent</td>
                                                <td>10% weightage</td>
                                            </tr>
                                            <tr>
                                                <td>b) HSC (Pre-Medical) / Equivalent Examination</td>
                                                <td>40% weightage</td>
                                            </tr>
                                            <tr>
                                                <td>c) Entry Test</td>
                                                <td>50% weightage</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    <p style={{marginLeft:'10px',fontSize:'14px'}}>
                                        <span style={{color: '#14062b',textDecoration: 'underline'}}>
                                            NOTE: Only Science subject (Biology, Chemistry and Physics or Mathematic) will be considered 
                                            in HSC result for making District Merit List.
                                        </span>
                                    </p>
                                    <p style={{marginLeft:'10px',fontSize:'14px'}}>
                                        <span>
                                            Candidate who is finally selected for admission on Open Merit or Self-Finance or any category 
                                            shall has to an oath through an affidavit of value Rs. 100 that he / shall complete the course 
                                            of Doctor of Physiotherapy (DPT) five (05) years. 
                                            <p style={{color: '#ba372a'}}>
                                                In case he / she leaves the National Institute of Physiotherapy &amp; Rehabilitation Medicine 
                                                (NIPRM) before completing the Course for any reason, he / she shall be submitted a Pay Order / 
                                                Demand Draft of <strong>Rs. 200, 000/- (Rupees Two Lacs Only)</strong> payable to the National 
                                                Institute of Physiotherapy &amp; Rehabilitation Medicine-Hyderabad as a penalty for wasting a 
                                                precious seat.
                                            </p>
                                        </span>
                                    </p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>
		    </div>
            <br></br>
            <br></br>
            <Footer></Footer>
        </div>
    )
}

export default Rules
