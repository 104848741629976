import React, { useEffect, useState } from 'react';
import Breadcumbs from '../../components/Breadcumbs';
import Footer from '../../components/Footer';
import ImageHeader from '../../components/ImageHeader';
import Navigationbar from '../../components/Navigationbar';
// import banner_1 from '../../images/banner_1.png';
import { Table } from 'react-bootstrap';
import axios from 'axios';

const Deadlines = () => {
    const items = [{item:'Home',url:'/'}]
    const itemActive = 'Admissions / Dates And Deadlines'
    
    const [admissions, setAdmissions] = useState({ adAppForm: "", adFormalitiesDate: "", adEligibleDate: "", adResultDate: "", adStatus: '', adTestDate: "", adTestFee: '', admissionFee: '', createdAt: "", id: '', lastDateToApply: '', securityDeposit: '', updatedAt: ""})

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL+'/admissions').then(response=>setAdmissions(response.data.data)).catch(error=>console.log(error))
    }, [])

    return (
        <div>
            <Navigationbar></Navigationbar>
            <ImageHeader image={"/images/banner_1.png"}></ImageHeader>
            <Breadcumbs items={items} actives={itemActive}></Breadcumbs>
            <br/>
            <br/>
            <div className="content-block">
            {/* <!-- About Us --> */}
			    <div className="section-area section-sp2">
                    <div className="container">
					    <div className="row">
						    {
                                admissions.adStatus?<p style={{color:"green",textAlign:'right'}}><strong>Admissions Are Now Open!!!</strong><br/></p>:<p style={{color:"blue",textAlign:'right'}}><strong>Admissions Are Closed!!!</strong><br/></p>
                            }
                            <p style={{textAlign:'right'}}><i>Updated on: {admissions.updatedAt.substring(0,10)}</i><br/></p>
                            
                            <Table bordered hover responsive>
                                <thead style={{background:'slategrey'}}>
                                    <tr>
                                        <th>Admission Schedule</th>
                                        <th>Doctor of Physiotherapy (DPT)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Last date for Application Submission</td>
                                        <td>{admissions.lastDateToApply}</td>
                                    </tr>
                                    <tr>
                                        <td>Announcement of Eligible Candidates</td>
                                        <td>{admissions.adEligibleDate}</td>
                                    </tr>
                                    <tr>
                                        <td>Admission Tests</td>
                                        <td>{admissions.adTestDate}</td>
                                    </tr>
                                    <tr>
                                        <td>Result Announcement</td>
                                        <td>{admissions.adResultDate}</td>
                                    </tr>
                                    <tr>
                                        <td>Admission Formalities</td>
                                        <td>{admissions.adFormalitiesDate}</td>
                                    </tr>
                                </tbody>
                            </Table>
                            <p style={{fontSize: '14pt',textDecoration:'underline',marginBottom:'10px',color:'#14062b'}}>Note</p>
                            <ul style={{fontSize: '14pt',listStyle:'circle',marginLeft:'25px'}}>
                                <li>Application for Self-Finance and Overseas can be obtained after display of Provisional Merit List.</li>
                                <li>Request for late submission of documents will not be entertained after the closing date for submission of application for admission.</li>
                                <li>Institute (NIPRM) reserves the right to change / modify the admission Schedule.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <br/>
            <Footer></Footer>
        </div>
    )
}

export default Deadlines
