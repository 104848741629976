import React, { useEffect, useState } from 'react'
import Breadcumbs from '../../components/Breadcumbs'
import Footer from '../../components/Footer'
import ImageHeader from '../../components/ImageHeader'
import Navigationbar from '../../components/Navigationbar'
// import banner_4 from '../../images/banner_4.png'
import { Card } from 'react-bootstrap';
import axios from 'axios';

const DegreePrograms = () => {
    const items = [{item:'Home',url:'/'}]
    const itemActive = 'Academics / Degree Programmes'

    const constructData = () => {
        let bachlorsArray = []
        let mastersArray = []
        let phdArray = []
        let certificationsArray = []

        degreePrograms.forEach(degree => {
            if(degree.degLevel === 'Bachlors'){
                bachlorsArray.push(degree);
            } else if(degree.degLevel === 'Masters'){
                mastersArray.push(degree);
            } else if(degree.degLevel === 'PhD'){
                phdArray.push(degree);
            } else if(degree.degLevel === 'Certification'){
                certificationsArray.push(degree);
            }
        });
        return {bachlor:bachlorsArray,master:mastersArray,PhD:phdArray,cert:certificationsArray};
    };

    const [degreePrograms, setDegreePrograms] = useState([{ id:'',degTitle: '',degImage: '',degProspectus: '',degFeePerSemesterMerit: '',degFeePerSemesterSelf: '',degFeePerSemesterOverseas: '',degLevel: '',createdAt: '',updatedAt: ''}])
    
    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL+'/degreeprograms').then(response=>setDegreePrograms(response.data.data)).catch(error=>console.log(error))    
    },[])

    let [data] = useState({ bachlor:[],master:[],PhD:[],cert:[] }) 
    data = constructData();


    return (
        <div>
            <Navigationbar></Navigationbar>
            <ImageHeader image={"/images/banner_4.png"}></ImageHeader>
            <Breadcumbs items={items} actives={itemActive}></Breadcumbs>
            <br></br>
            <br></br>

            <div className="content-block">
                <div className="section-area section-sp1 gallery-bx">
                    <div className="container">
                        <div className="clearfix">
                            {/* bachlors */}
                            {
                                data.bachlor[0]?
                                    <>
                                    <h1 className="text-left" style={{fontWeight: 'lighter', color:'rgb(64 18 139)', padding: '0px',paddingTop: '7px',marginBottom:'0px'}}>
                                        Bachelors Programs
                                    </h1>
                                    <hr style={{height: '5px',opacity:'initial',background:'rgb(55 8 131)',borderWidth:'5px',margin:'0',marginBottom:'10px',borderRadius:'10px'}}/>
                                    
                                    <ul className="row" style={{padding:'0px 10px'}}>
                                        {
                                            data.bachlor.map((degree,index)=>{
                                                return(
                                                    <li key={index} className="col-lg-4 col-md-6 col-sm-12" style={{padding:'5px'}}>
                                                        <Card style={{paddingLeft:'0px',paddingRight:'0px'}}> 
                                                            <Card.Img variant="top" src={process.env.REACT_APP_API_URL+'/'+degree.degImage} style={{width:'100%',height:'250px'}}/>
                                                            <Card.Body>
                                                                <Card.Title>{degree.degTitle}</Card.Title>
                                                                {
                                                                    degree.degProspectus?<a className="btn btn-secondary" href={process.env.REACT_APP_API_URL+'/'+degree.degProspectus} style={{width:'100%', background:'rgb(55 8 131)'}}>Download Prospectus</a>:null
                                                                }
                                                            </Card.Body>
                                                        </Card>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                    </>
                                    :null
                            }
                            {/* masters */}
                            {
                                data.master[0]?
                                    <>
                                    <h1 className="text-left" style={{fontWeight: 'lighter', color:'rgb(64 18 139)', padding: '0px',paddingTop: '7px',marginBottom:'0px'}}>
                                        Masters Programs
                                    </h1>
                                    <hr style={{height: '5px',opacity:'initial',background:'rgb(55 8 131)',borderWidth:'5px',margin:'0',marginBottom:'10px',borderRadius:'10px'}}/>
                                    
                                    <ul className="row" style={{padding:'0px 10px'}}>
                                        {
                                            data.master.map((degree,index)=>{
                                                return(
                                                    <li key={index} className="col-lg-4 col-md-6 col-sm-12" style={{padding:'5px'}}>
                                                        <Card style={{paddingLeft:'0px',paddingRight:'0px'}}> 
                                                            <Card.Img variant="top" src={process.env.REACT_APP_API_URL+'/'+degree.degImage} style={{width:'100%',height:'250px'}}/>
                                                            <Card.Body>
                                                                <Card.Title><a href="as">{degree.degTitle}</a></Card.Title>
                                                                {
                                                                    degree.degProspectus?<a className="btn btn-secondary" href={process.env.REACT_APP_API_URL+'/'+degree.degProspectus} style={{width:'100%', background:'rgb(55 8 131)'}}>Download Prospectus</a>:null
                                                                }
                                                            </Card.Body>
                                                        </Card>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                    </>
                                    :null
                            }
                            {/* PhD */}
                            {
                                data.PhD[0]?
                                    <>
                                    <h1 className="text-left" style={{fontWeight: 'lighter', color:'rgb(64 18 139)', padding: '0px',paddingTop: '7px',marginBottom:'0px'}}>
                                        PhD Programs
                                    </h1>
                                    <hr style={{height: '5px',opacity:'initial',background:'rgb(55 8 131)',borderWidth:'5px',margin:'0',marginBottom:'10px',borderRadius:'10px'}}/>
                                    
                                    <ul className="row" style={{padding:'0px 10px'}}>
                                        {
                                            data.PhD.map((degree,index)=>{
                                                return(
                                                    <li key={index} className="col-lg-4 col-md-6 col-sm-12" style={{padding:'5px'}}>
                                                        <Card style={{paddingLeft:'0px',paddingRight:'0px'}}> 
                                                            <Card.Img variant="top" src={process.env.REACT_APP_API_URL+'/'+degree.degImage} style={{width:'100%',height:'250px'}}/>
                                                            <Card.Body>
                                                                <Card.Title><a href="as">{degree.degTitle}</a></Card.Title>
                                                                {
                                                                    degree.degProspectus?<a className="btn btn-secondary" href={process.env.REACT_APP_API_URL+'/'+degree.degProspectus} style={{width:'100%', background:'rgb(55 8 131)'}}>Download Prospectus</a>:null
                                                                }
                                                            </Card.Body>
                                                        </Card>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                    </>
                                    :null
                            }
                            {/* Certifications */}
                            {
                                data.cert[0]?
                                    <>
                                    <h1 className="text-left" style={{fontWeight: 'lighter', color:'rgb(64 18 139)', padding: '0px',paddingTop: '7px',marginBottom:'0px'}}>
                                        Certifications
                                    </h1>
                                    <hr style={{height: '5px',opacity:'initial',background:'rgb(55 8 131)',borderWidth:'5px',margin:'0',marginBottom:'10px',borderRadius:'10px'}}/>
                                    
                                    <ul className="row" style={{padding:'0px 10px'}}>
                                        {
                                            data.cert.map((degree,index)=>{
                                                return(
                                                    <li key={index} className="col-lg-4 col-md-6 col-sm-12" style={{padding:'5px'}}>
                                                        <Card style={{paddingLeft:'0px',paddingRight:'0px'}}> 
                                                            <Card.Img variant="top" src={process.env.REACT_APP_API_URL+'/'+degree.degImage} style={{width:'100%',height:'250px'}}/>
                                                            <Card.Body>
                                                                <Card.Title><a href="as">{degree.degTitle}</a></Card.Title>
                                                                {
                                                                    degree.degProspectus?<a className="btn btn-secondary" href={process.env.REACT_APP_API_URL+'/'+degree.degProspectus} style={{width:'100%', background:'rgb(55 8 131)'}}>Download Prospectus</a>:null
                                                                }
                                                            </Card.Body>
                                                        </Card>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                    </>
                                    :null
                            }
                        </div>
                    </div>
                </div>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <Footer></Footer>
        </div>
    )
}

export default DegreePrograms
