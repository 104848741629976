import React, { useEffect } from 'react';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import Carousel from 'react-multi-carousel';
import 'photoswipe/style.css';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
};

export default function ImageSlider(props) {
    
    useEffect(() => {
        let lightbox = new PhotoSwipeLightbox({
          gallery: '#' + props.galleryID,
          children: 'a',
          pswpModule: () => import('photoswipe'),
        });
        lightbox.init();
    
        return () => {
          lightbox.destroy();
          lightbox = null;
        };
    }, [props.galleryID]);
    
    return (
        <div className="pswp-gallery" id={props.galleryID}>
            <Carousel 
                swipeable={true}
                draggable={false}
                // showDots={true}
                responsive={responsive}
                //ssr={true} // means to render carousel on server-side.
                // infinite={true}
                // autoPlay={this.props.deviceType !== "mobile" ? true : false}
                autoPlaySpeed={1000}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                // deviceType={this.props.deviceType}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
            >
                {props.images.map((image, index) => (
                    <a
                        href={image.largeURL}
                        data-pswp-width={image.width}
                        data-pswp-height={image.height}
                        key={props.galleryID + '-' + index}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src={image.thumbnailURL} style={{width:image.width_thumb, height:image.height_thumb, margin:'10px'}} alt="" />
                    </a>
                ))}
            </Carousel>
        </div>
    )
}