import React, { useState, useEffect } from 'react'
import Breadcumbs from '../components/Breadcumbs'
import Footer from '../components/Footer'
import ImageHeader from '../components/ImageHeader'
import Navigationbar from '../components/Navigationbar'
// import banner_16 from '../images/banner_16.png'
import { Nav,Row,Col,Tab,Container } from 'react-bootstrap'
import axios from 'axios'

const Jobs = () => {
    const items = [{item:'Home',url:'/'}]
    const itemActive = 'Careers'

    const [jobs,setJobs] = useState([ { id:'', title:'', AnnouncementFile:'', ApplicationFile:'', lastDate:'', createdAt:'', updatedAt:''} ])
    
    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL+'/jobs').then(response=>setJobs(response.data.data)).catch(error=>console.log(error))
    }, [])

    return (
        <div>
            <Navigationbar></Navigationbar>
            <ImageHeader image={"/images/banner_16.png"}></ImageHeader>
            <Breadcumbs items={items} actives={itemActive}></Breadcumbs>
            <br/>
            <Container>
                <Tab.Container id="left-tabs-example" defaultActiveKey="zero">
                    <Row>
                        <Col sm={3}>
                            <h3>Quick links</h3>
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="zero">Careers</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content>
                                <Tab.Pane eventKey="zero">
                                    <h3>Jobs</h3>
                                    <ul className="row" style={{padding:"0px 10px"}}>
                                    {
                                        jobs.map((job,index)=>{
                                            return(
                                                <div key={index} className="news-update" style={{display:'block'}}>
                                                    <div className="row">
                                                        <div className="col-md-2">
                                                            Last Date to Apply
                                                        </div>
                                                        <p className="col-md-9">{job.title}</p>
                                                    </div>
                                                    
                                                    <div className="row">
                                                        <div className="col-md-2" style={{margin:"10px 0px"}}>{job.lastDate}</div>
                                                        <div className="col-md-3 btn-group">
                                                                <a className="btn btn-secondary" href="mailto:info@niprm.edu.pk">Apply</a>
                                                        </div>
                                                        {true?
                                                            <div className="col-md-3 btn-group">
                                                                <a className="btn btn-secondary" href={process.env.REACT_APP_API_URL+'/'+job.AnnouncementFile}>Job Announcement</a>
                                                            </div>:null
                                                        }
                                                        {job.ApplicationFile?
                                                            <div className="col-md-3 btn-group">
                                                                <a className="btn btn-secondary" href={process.env.REACT_APP_API_URL+'/'+job.ApplicationFile}>Job Application Form</a>
                                                            </div>:null
                                                        }
                                                    </div>
                                                    
                                                </div>
                                            )
                                        })
                                    }
                                    </ul>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>
            <br />
            <br />
            <Footer></Footer>
        </div>
    )
}

export default Jobs