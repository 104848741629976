import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import {BrowserRouter, Route, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css'

import Home from './screens/Home';
import Deadlines from './screens/admissions/Deadlines';
import DegreePrograms from './screens/academics/DegreePrograms';
import FeeStructure from './screens/admissions/FeeStructure';
import Rules from './screens/admissions/Rules';
import HowToApply from './screens/admissions/HowToApply';
import ExamPolicy from './screens/academics/ExamPolicy';
import DisciplinePolicy from './screens/academics/DisciplinePolicy';
import ExamSchedule from './screens/academics/ExamSchedule';
import StudentResults from './screens/academics/StudentResults';
import TimeTable from './screens/academics/TimeTable';
import Faculty from './screens/Faculty';
import NewsEvents from './screens/NewsEvents';
import Facilities from './screens/Facilities';
import Jobs from './screens/Jobs';
import AboutUs from './screens/AboutUs';
import ContactUs from './screens/ContactUs';
import Departments from './screens/Departments';
import Hospitals from './screens/Hospitals';

const App = () => {
    
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' Component={Home} />
                <Route path='/admissions/datesdeadlines' Component={Deadlines} />
                <Route path='/admissions/feestructure' Component={FeeStructure} />
                <Route path='/admissions/howtoapply' Component={HowToApply} />
                <Route path='/admissions/rules' Component={Rules} />
                <Route path='/academics/degreeprograms' Component={DegreePrograms} />
                <Route path='/academics/exampolicy' Component={ExamPolicy} />
                <Route path='/academics/disciplinepolicy' Component={DisciplinePolicy} />
                <Route path='/academics/timetable' Component={TimeTable} />
                <Route path='/academics/examsschedule' Component={ExamSchedule} />
                <Route path='/academics/examsresults' Component={StudentResults} />
                <Route path='/faculty' Component={Faculty} />
                <Route path='/newsandevents/:activeTab?' Component={NewsEvents} />
                <Route path='/facilities' Component={Facilities} />
                <Route path='/careers' Component={Jobs} />
                <Route path='/aboutus' Component={AboutUs} />
                <Route path='/contactus' Component={ContactUs} />
                <Route path='/departments/:dept' Component={Departments} />
                <Route path='/hospitals/:hospital' Component={Hospitals} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;