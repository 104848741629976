import React from 'react'
import Breadcumbs from '../../components/Breadcumbs'
import Footer from '../../components/Footer'
import ImageHeader from '../../components/ImageHeader'
import Navigationbar from '../../components/Navigationbar'
// import banner_6 from '../../images/banner_6.png'
import { Container } from 'react-bootstrap';

const DisciplinePolicy = () => {
    const items = [{item:'Home',url:'/'}]
    const itemActive = 'Academics / Disciplinary Policy'

    return (
        <div>
            <Navigationbar></Navigationbar>
            <ImageHeader image={"/images/banner_6.png"}></ImageHeader>
            <Breadcumbs items={items} actives={itemActive}></Breadcumbs>
            <br/>
            <br/>
            <Container>
                {/* <h2> Academic Honesty</h2> */}
                {/* <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Cheating</Accordion.Header>
                        <Accordion.Body>
                            
                        </Accordion.Body>
                    </Accordion.Item>
                        
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Plagiarism</Accordion.Header>
                        <Accordion.Body>
                           
                        </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Other Academic Violations</Accordion.Header>
                        <Accordion.Body>
                           
                        </Accordion.Body>
                    </Accordion.Item>
                    
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>Penalties for Academic Dishonesty</Accordion.Header>
                        <Accordion.Body>
                            
                        </Accordion.Body>
                    </Accordion.Item>

                </Accordion> */}
            </Container>
            <br/>
            <Footer></Footer>
        </div>
    )
}

export default DisciplinePolicy
