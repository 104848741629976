import React from 'react'

const ImageHeader = (props) => {
    
    return (
        <div style={{position: 'relative',textAlign: 'center',color: 'azure',marginTop: "80px"}}>
            <img src={props.image} alt="Snow" style={{width:'100%', height:'300px',filter: "contrast(0.5)"}} />
            
            <div style={{fontFamily: 'cursive',fontSize: 'xx-large',position: 'absolute',top: '50%',left: '40%'}}>{props.text}</div>
        </div>
    )
}

export default ImageHeader
