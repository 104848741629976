import React, { useState, useEffect } from 'react'
import { Nav, Tab, Row, Col } from 'react-bootstrap';
import axios from 'axios'

const NewFeeds = () => {
    
    const month = ["Jan","Feb","Mar","Apr","May","June","July","Aug","Sept","Oct","Nov","Dec"];

    // const [announcements,setAnnouncements] = useState([ { id:'', title: '', File: '', Date: '', createdAt: '', updatedAt: ''} ])
    const [events,setEvents] = useState([ { id:'', title:'', eventImage:'', attachment:'', dateOccurred:'', createdAt:'', updatedAt:''} ])
    const [jobs,setJobs] = useState([ { id:'', title:'', AnnouncementFile:'', ApplicationFile:'', lastDate:'', createdAt:'', updatedAt:''} ])
    
    useEffect(() => {
        // axios.get(process.env.REACT_APP_API_URL+'/announcements').then(response=>setAnnouncements(response.data.data)).catch(error=>console.log(error))
        axios.get(process.env.REACT_APP_API_URL+'/events').then(response=>setEvents(response.data.data)).catch(error=>console.log(error))
        axios.get(process.env.REACT_APP_API_URL+'/jobs').then(response=>setJobs(response.data.data)).catch(error=>console.log(error))
    }, [])
    
    return (
        <div className="container">
            <div className="row">
                <h3 style={{margin: '15px 0px 0px 0px'}}>News Feed & Updates</h3>
                <hr />
                <Tab.Container id="left-tabs-example" defaultActiveKey="third">
                    <Row>
                        <Col sm={3} style={{borderRight:'1px solid lightgrey',height:'180px', paddingRight:'0px'}}>
                            <Nav variant="pills" className="flex-column">
                                {/* <Nav.Item>
                                    <Nav.Link eventKey="first">Announcements</Nav.Link>
                                </Nav.Item> */}
                                
                                <Nav.Item>
                                    <Nav.Link eventKey="second">Events</Nav.Link>
                                </Nav.Item>
                                
                                <Nav.Item>
                                    <Nav.Link eventKey="third">Careers</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        
                        <Col sm={9}>
                            <Tab.Content>
                                {/* <Tab.Pane style={{height:'200px',overflowY:'auto',overflowX:'hidden'}} eventKey="first">
                                    {
                                        announcements.map((announcement,index)=>{
                                            return(
                                                <div className="news-update">
                                                    <div className="update-event_icon">
                                                        <div className="event_month">{announcement.Date.substring(4, 8)}</div>
                                                        <div className="event_day">{announcement.Date.substring(8, 10)}</div>
                                                    </div>
                                                    <p className="col-md-10">{announcement.title}</p>
                                                    <div className="col-md-1 btn-group">
                                                        {announcement.File?<a style={{backgroundColor: "#14062b !important",borderColor: "#14062b"}} className="btn btn-secondary" href={process.env.REACT_APP_API_URL+'/'+announcement.File}><i className="fa fa-paperclip"></i></a>:null} 
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Tab.Pane> */}
                                
                                <Tab.Pane style={{height:'200px',overflowY:'auto',overflowX:'hidden'}} eventKey="second">
                                    {
                                        events.map((event,index)=>{
                                            return(
                                                <div key={index} className="news-update">
                                                    <div className="update-event_icon">
                                                        <div className="event_month">{month[Number(event.dateOccurred.substring(5, 7))-1]}</div>
                                                        <div className="event_day">{event.dateOccurred.substring(8, 10)}</div>
                                                    </div>
                                                    <p className="col-md-10">{event.title}</p>
                                                    <div className="col-md-1 btn-group">
                                                        {event.attachment?<a style={{backgroundColor: "#14062b !important",borderColor: "#14062b"}} className="btn btn-secondary" href={process.env.REACT_APP_API_URL+'/'+event.attachment}><i className="fa fa-paperclip"></i></a>:null}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Tab.Pane>
                                
                                <Tab.Pane style={{height:'200px',overflowY:'auto',overflowX:'hidden'}} eventKey="third">
                                    {
                                        jobs.map((job,index)=>{
                                            return(
                                                <div key={index} className="news-update">
                                                    <div className="update-event_icon">
                                                        <div className="event_month">{job.lastDate.substring(4, 8)}</div>
                                                        <div className="event_day">{job.lastDate.substring(8, 10)}</div>
                                                    </div>
                                                    <div className="col-md-10">
                                                        {job.title}
                                                        <br/>
                                                        <br/>
                                                        <div style={{display:'flex'}}>
                                                            <div className="btn-group" style={{margin: '0px 10px'}}>
                                                                    <a className="btn btn-secondary" href="mailto:info@niprm.edu.pk">Apply</a>
                                                            </div>
                                                            {job.ApplicationFile?
                                                                <div className="btn-group" style={{margin: '0px 10px'}}>
                                                                    <a className="btn btn-secondary" href={process.env.REACT_APP_API_URL+'/'+job.ApplicationFile}>Application Form</a>
                                                                </div>    
                                                                :null
                                                            }
                                                            
                                                            {job.AnnouncementFile?
                                                                <div className="btn-group" style={{margin: '0px 10px'}}>
                                                                    <a className="btn btn-secondary" href={process.env.REACT_APP_API_URL+'/'+job.AnnouncementFile}>Job Announcement</a>
                                                                </div>
                                                                :null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        </div>
    )
}

export default NewFeeds