import React from 'react';
import Navigationbar from '../components/Navigationbar';
import MainSlider from '../components/MainSlider';
import NewFeeds from '../components/NewFeeds';
import VCMessage from '../components/VCMessage';
import MediaCell from '../components/MediaCell';
import ChooseUs from '../components/ChooseUs';
import LifeAtCampus from '../components/LifeAtCampus';
import Footer from '../components/Footer';
import Tickers from '../components/Tickers';


const Home = () => {
  
    return (
        <div>
            <Navigationbar></Navigationbar>
            <MainSlider></MainSlider>
            {/* <hr style={{border: '3px solid #535252', padding: '0px', margin: '0px'}} /> */}
            <Tickers></Tickers>
            <NewFeeds></NewFeeds>
            <VCMessage></VCMessage>
            <MediaCell></MediaCell>
            <br/>
            <br/>
            <ChooseUs></ChooseUs>
            <LifeAtCampus></LifeAtCampus>
            <Footer></Footer>
            
        </div>
    );
}

export default Home;