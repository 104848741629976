import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Breadcumbs from '../components/Breadcumbs'
import Footer from '../components/Footer'
import ImageHeader from '../components/ImageHeader'
import Navigationbar from '../components/Navigationbar'
// import banner_10 from '../images/banner_10.png'
import { Nav,Row,Col,Tab,Container } from 'react-bootstrap'
import axios from 'axios'
import EventCard from '../components/EventCard'

const NewsEvents = () => {
    const { activeTab } = useParams()

    const items = [{item:'Home',url:'/'}]
    const itemActive = 'NEWS & EVENTS'

    let [upcomingEvents, setUpcomingEvents] = useState([ { id:'', title:'', eventImages: [], eventDescription: '', dateOccurred:'', createdAt:'', updatedAt:''}])
    let [pastEvents, setPastEvents] = useState([ { id:'', title:'', eventImages: [], eventDescription: '', dateOccurred:'', createdAt:'', updatedAt:''}])
    
    const [announcements,setAnnouncements] = useState([ { id:'', title: '', File: '', Date: '', createdAt: '', updatedAt: ''} ])
    // const [events,setEvents] = useState([ { id:'', title:'', eventImages: [], eventDescription: '', dateOccurred:'', createdAt:'', updatedAt:''}])
    
    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL+'/announcements').then(response=>setAnnouncements(response.data.data)).catch(error=>console.log(error))
        axios.get(process.env.REACT_APP_API_URL+'/events')
        .then(response=>{
            const arrayPast = []
            const arrayUpcoming = []
            const res = response.data.data
            // console.log(res)
            for (let i = 0; i < res.length; i++) {
                const element = res[i];
                let event = {id: element.id, title: element.title, eventImages: [], eventDescription: element.eventDescription, dateOccurred: element.dateOccurred, createdAt: element.createdAt, updatedAt: element.updatedAt}
                let images = element.eventImages.split(";")
                for (let j = 0; j < images.length; j++) {
                    const img = images[j];
                    event.eventImages.push({ largeURL: process.env.REACT_APP_API_URL+'/'+img, thumbnailURL: process.env.REACT_APP_API_URL+'/'+img, width: 4032, height: 3024, width_thumb: 400, height_thumb: 300 })
                }
                let today = new Date()
                let eventDate = new Date(event.dateOccurred)
                // console.log(today, eventDate)
                if(eventDate < today){
                    arrayPast.push(event)
                } else {
                    arrayUpcoming.push(event)
                }

            }
            setPastEvents(arrayPast)
            setUpcomingEvents(arrayUpcoming)
        })
        .catch(
            error=>console.log(error)
        )
    }, [])
    
    return (
        <div>
            <Navigationbar></Navigationbar>
            <ImageHeader image={"/images/banner_10.png"}></ImageHeader>
            <Breadcumbs items={items} actives={itemActive}></Breadcumbs>
            <br/>
            <Container>
                <Tab.Container id="left-tabs-example" defaultActiveKey={activeTab==="announcements"?"zero":"first"}>
                    <Row>
                        <Col sm={3}>
                            <h3>Quick links</h3>
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="zero">Announcements</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="first">Events</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content>
                                <Tab.Pane eventKey="zero">
                                    <h3>Announcements</h3>
                                    <ul className="row" style={{padding:"0px 10px"}}>
                                    {
                                        announcements.map((announcement,index)=>{
                                            return(
                                                <div className="news-update">
                                                    <div className="col-md-2">
                                                        {announcement.Date}
                                                    </div>
                                                    <p className="col-md-7">{announcement.title}</p>
                                                    {announcement.File?
                                                        <div className="col-md-3 btn-group">
                                                            <a style={{backgroundColor: "#14062b !important",borderColor: "#14062b"}} className="btn btn-secondary" href={process.env.REACT_APP_API_URL+'/'+announcement.File}>Download Attachment</a>
                                                        </div>:null
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                    </ul>
                                </Tab.Pane>

                                <Tab.Pane eventKey="first">
                                    <Tab.Container id="left-tabs-example" defaultActiveKey="pastEvents">
                                        <div className="row" style={{marginBottom: '10px'}}>
                                            <Nav variant="pills" style={{justifyContent: 'center'}}>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="pastEvents">Past Events</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="upcomingEvents">Upcoming Events</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </div>
                                        <div className="row">
                                            <Tab.Content>
                                                <Tab.Pane eventKey="pastEvents">
                                                        <EventCard 
                                                            galleryID={"past-events"} 
                                                            events={pastEvents} 
                                                            width={400} 
                                                            height={300} 
                                                        />
                                                </Tab.Pane>
                                                
                                                <Tab.Pane eventKey="upcomingEvents">
                                                    <EventCard 
                                                            galleryID={"upcoming-events"} 
                                                            events={upcomingEvents} 
                                                            width={400} 
                                                            height={300} 
                                                        />
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </div>
                                    </Tab.Container>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>
            <br />
            <br />
            <Footer></Footer>
        </div>
    )
}

export default NewsEvents
