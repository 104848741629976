import React from 'react'
import Breadcumbs from '../components/Breadcumbs'
import Footer from '../components/Footer'
import ImageHeader from '../components/ImageHeader'
import Navigationbar from '../components/Navigationbar'
import { Container } from 'react-bootstrap'
import { Accordion } from 'react-bootstrap';

const AboutUs = () => {
    const items = [{item:'Home',url:'/'}]
    const itemActive = 'About Us'

    return (
        <div>
            <Navigationbar></Navigationbar>
            <ImageHeader image={"/images/banner_14.png"}></ImageHeader>
            <Breadcumbs items={items} actives={itemActive}></Breadcumbs>
            <br/>
            <br/>
            <Container>
            <Accordion>
                <Accordion.Item eventKey='0'>
                    <Accordion.Header>MISSION</Accordion.Header>
                    <Accordion.Body>
                        <p>TO IMPART THE EDUCATION OF PHYSIOTHERAPY TO THE VALUED SOCIETY IN ORDER TO FULFILL, 
                            PROVIDE AND ENHANCE THE HEALTH FACILITIES REGARDING PHYSIOTHERAPY, DEVOTING TO PROMOTE THE 
                            QUALITY OF EDUCATION TOUCHING THE CLIMAX WITH PROFESSIONAL EXCEL COMPETES FROM NATIONAL TO 
                            INTERNATIONAL STANDARDS.
                        </p>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey='1'>
                    <Accordion.Header>About NIPRM</Accordion.Header>
                    <Accordion.Body>
                        <p>
                            The National Institute of Physiotherapy & Rehabilitation Medicine, Hyderabad was established 
                            in the year 2016.
                        </p>
                        <p>
                            The National Institute of Physiotherapy & Rehabilitation Medicine (NIPRM) is committed to 
                            offer quality education in the society deals to Physiotherapy, this Institute is affiliated 
                            with country’s 1st Medical & Health Public Sector University i.e. Liaquat University of Medical & 
                            Health Sciences, (LUMHS) Jamshoro.
                        </p>
                        <p> 
                            The Course Curriculum of Doctor of Physiotherapy as prescribed / designed by Higher Education Commission (HEC) 
                            Pakistan is to be taught accordingly. This Institute is fully equipped with modern high tech equipments of 
                            Anatomy, Physiology, Kinesiology and Medical Physics Lab. This Institute is contributing the 
                            valuable service to the community by the knowledgeable and skillful Physiotherapists, 
                            experienced Teaching and Technical Staff and the Physiotherapy Graduate will be available in 
                            market can play their pivotal role to provision the best health services to the needy patients.
                        </p>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>                    
            </Container>
            <br />
            <br />
            <Footer></Footer>
        </div>
    )
}

export default AboutUs