import React, { useState, useEffect } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import axios from 'axios';

const MediaCell = () => {
    
    const [events,setEvents] = useState([ { id:'', title:'', eventImages: [], eventDescription: '', dateOccurred:'', createdAt:'', updatedAt:''}])

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL+'/events')
        .then(response=>{
            const array = []
            const res = response.data.data
            for (let i = 0; i < res.length; i++) {
                const element = res[i];
                let event = {id: element.id, title: element.title, eventImages: [], eventDescription: element.eventDescription, dateOccurred: element.dateOccurred, createdAt: element.createdAt, updatedAt: element.updatedAt}
                let images = element.eventImages.split(";")
                for (let j = 0; j < images.length; j++) {
                    const img = images[j];
                    event.eventImages.push({ largeURL: process.env.REACT_APP_API_URL+'/'+img, thumbnailURL: process.env.REACT_APP_API_URL+'/'+img, width: 4032, height: 3024, width_thumb: 400, height_thumb: 300 })
                }
                array.push(event)
            }
            setEvents(array)
        })
        .catch(error=>console.log(error))
    }, [])

    return (
        <section id="rs-portfolio" className="rs-portfolio defutl-style gray-color">
            <div className="container">
                <div className="sec-title" style={{display:'flex'}}>
                    <h3>Media Cell&nbsp;&nbsp;<small><a href="https://www.facebook.com/NiprmHyd/"><i className="fa fa-facebook-square"></i> Facebook Page</a></small></h3>
                </div>
                <div className="row">
                    {
                        events.length===0?
                        <p className="mediacells">No Events Available at the Moment...</p>
                        :<Carousel 
                            className="mediacells" 
                            showThumbs={false} 
                            centerMode={false} 
                            autoPlay={true} 
                            interval="5000" 
                            transitionTime="3000"
                            showStatus={false} 
                            infiniteLoop={true}
                        >
                            {   
                                events.map((event,index)=>{
                                    return(
                                        <div key={index}>
                                            <img className="mediacells"src={event?.eventImages[0]?.largeURL} alt='' />
                                            <button className="legend">{event.title}</button>
                                        </div>          
                                    )
                                })
                            }
                        </Carousel>
                    }
                </div>
            </div>
        </section>
    )
}

export default MediaCell
