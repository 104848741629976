import React, { useState, useEffect } from 'react'
import { Carousel } from 'react-bootstrap';
import axios from 'axios'

const MainSlider = () => {
    const [slides,setSlides] = useState([
        {
            createdAt: '',
            description: "",
            id: '',
            slideImage: '',
            slideStatus: '',
            title: '',
            updatedAt: ''
        }
    ])
    
    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL+'/sliders').then(response=>{
            // console.log(response.data.data)
            setSlides(response.data.data)
        }).catch(error=>{
            console.log(error)
        })
        
    }, [])
    return (
        <div style={{height:"700px", width:"100%",marginTop: "80px"}}>
            <Carousel style={{height:"700px", width:"100%"}}>
                
                {
                    slides.map((slide,index)=>{
                        return( 
                            slide.slideStatus?
                            <Carousel.Item key={index} interval={2000}>
                                <img
                                    className="d-block"
                                    src={process.env.REACT_APP_API_URL+'/'+slide.slideImage}
                                    alt=""
                                    style={{height:"700px", width:"100%"}}
                                />
                                {/* <Carousel.Caption>
                                    <h2 style={{color:'whitesmoke'}}>{slide.title}</h2>
                                    <p style={{color:'whitesmoke'}}>{slide.description}</p>
                                </Carousel.Caption> */}
                            </Carousel.Item>:null
                        )
                    })
                }
            </Carousel>
        </div>
    )
}

export default MainSlider