import React from 'react'
import Breadcumbs from '../components/Breadcumbs'
import Footer from '../components/Footer'
import ImageHeader from '../components/ImageHeader'
import Navigationbar from '../components/Navigationbar'
// import banner_15 from '../images/banner_15.png'

const ContactUs = () => {
    const items = [{item:'Home',url:'/'}]
    const itemActive = 'Contact Us'

    return (
        <div>
            <Navigationbar></Navigationbar>
            <ImageHeader image={"/images/banner_15.png"}></ImageHeader>
            <Breadcumbs items={items} actives={itemActive}></Breadcumbs>
            <div style={{display:'flex'}}>
                <div className="col-md-4" style={{background:'whitesmoke',width:'200',height:"600px"}}>
                    <section id="rs-contact" className="rs-contact" style={{height:'600px'}}>
                        <div className="row" style={{height:'600px'}}>
                            <div className="contact-bg" style={{marginTop:'0px', height:'600px',paddingRight:'0px'}}>
                                <div className="margin" style={{height:'600px'}}>
                                    <div className="contact-address" style={{background:'#161518', paddingTop:'40px', height:'600px'}}>
                                        <div className="contact-address-title">
                                            <p className="contact-title">Contact Us</p>
                                            <p>If you have any query or want to let us know something, please get in touch.
                                                You can contact us on Email or Phone given below...
                                            </p>
                                        </div>
                                        <div className="address-item">
                                            <div className="address-icon">
                                                <i className="fa fa-phone"></i>
                                            </div>
                                            <div className="address-text">
                                                (022) 2661352
                                            </div>
                                        </div>
                                        <div className="address-item">
                                            <div className="address-icon">
                                                <i className="fa fa-map-marker"></i>
                                            </div>
                                            <div className="address-text">
                                                Plot # C/47, Beside Ink City Park, Dr Saleh Memon Rd, Hyderabad, Sindh, Pakistan.
                                            </div>
                                        </div>
                                        <div className="address-item">
                                            <div className="address-icon">
                                                <i className="fa fa-envelope-open-o"></i>
                                            </div>
                                            <div className="address-text">
                                                Info@NIPRM.edu.pk
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div className="col-md-8">
                    <iframe 
                        title="a" 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3603.525707905676!2d68.34932111485011!3d25.42068218379276!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x394c77b5c6a2e3e7%3A0xb17a74ca2dbf2350!2sNational%20Institute%20of%20Physiotherapy%20NIPRM!5e0!3m2!1sen!2s!4v1632153536825!5m2!1sen!2s"
                        width="100%" 
                        height="600px" 
                        frameBorder="0" 
                        style={{border:"0"}} 
                        allowFullScreen="" 
                        aria-hidden="false" 
                        tabIndex="0">
                    </iframe>
                </div>
            </div>
            <Footer></Footer>
        </div>
    )
}

export default ContactUs