import React from 'react';

const Footer = () => {
    return (
		<footer>
			<div className="footer-top">
				<div className="pt-exebar">
					<div className="container" style={{paddingBottom:'5px'}}>
						<div className="d-flex align-items-stretch">
							<div className="pt-logo mr-auto">
								<a id="foot_img" href="/"><img src='/Logo.png' alt="" /></a> {/* style={{width:window.innerWidth*0.2}} */}
								<h2 id="foot_text">NATIONAL INSTITUTE OF PHYSIOTHERAPY AND REHABILITATION MEDICINE</h2> {/* style={{fontSize:window.innerWidth*0.03,margin:'0'}} */}
							</div>
						</div>
					</div>
				</div>
				<div className="container">
					<div className="row">
						
						<div className="col-lg-2 col-md-12 col-sm-12 footer-col-4">
							<div className="widget footer_widget">
								<br />
								<h5 className="footer-title">NIPRM</h5>
								<ul>
									<li><a href="/">Home</a></li>
									<li><a href="/aboutus">About Us</a></li>
									<li><a href="/contactus">Contact Us</a></li>
								</ul>
							</div>
						</div>
						
						<div className="col-lg-2 col-md-12 col-sm-12 footer-col-4">
							<div className="widget footer_widget">
								<br />
								<h5 className="footer-title">Get In Touch</h5>
								<ul>
									<li><a href="/newsandevents">News</a></li>
									<li><a href="/newsandevents">Events</a></li>
									<li><a href="https://www.facebook.com/NiprmHyd/">Facebook</a></li>
								</ul>
							</div>
						</div>
						
						<div className="col-lg-4 col-md-12 col-sm-12 footer-col-4">
							<div className="widget">
								<br />
								<h5 className="footer-title" style={{fontSize: "20px"}}>OUR CAMPUS</h5>
								<p className="text-capitalize m-b20">Location: P.No: C/47, Beside Ink City Park, Dr Saleh Memon Rd, Hyderabad, Sindh, Pakistan.</p>
								<p className="text-capitalize m-b20">Email: info@niprm.edu.pk</p>
								<p className="text-capitalize m-b20">Contact: (022) 2661352</p>
							</div>
						</div>
						
						<div className="col-lg-4 col-md-12 col-sm-12 footer-col-4" id="iframe_box">
							<iframe 
								title="a" 
								src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3603.525707905676!2d68.34932111485011!3d25.42068218379276!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x394c77b5c6a2e3e7%3A0xb17a74ca2dbf2350!2sNational%20Institute%20of%20Physiotherapy%20NIPRM!5e0!3m2!1sen!2s!4v1632153536825!5m2!1sen!2s"
								width="300" 
								height="250" 
								frameBorder="0" 
								style={{border:"0"}} 
								allowFullScreen="" 
								aria-hidden="false" 
								tabIndex="0">
							</iframe>
						</div>
					</div>
				</div>
			</div>
		</footer>
    )
}

export default Footer
