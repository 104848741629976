import React, { useState } from 'react'

const LifeAtCampus = () => {

    const [selectedImage,setSelectedImage] = useState('/images/image_6.jpeg')

    return (
        // <!-- Services Start -->
        <section id="rs-services" className="rs-services services-carousel sec-spacer gray-color">
            <div className="container">
                <div className="sec-title text-center">
                    <h3>STUDYING AT NIPRM</h3>
                    <p>Follows the semester curriculum according to LUMHS Jamshoro, the most modern and student <br/>friendly teaching systems</p>
                </div>
                
                <div className="clfeatures">
                    <div className="row common-height clearfix">
                        <div className="col-md-7 col-sm-12 col-xs-12 nopadding-sm clearfix">
                            <div className="vertical-middle">
                                <div className="col-padding clearfix">
                                    <div id="item-thumb" className="item-thumb">

                                        <div onClick={()=>setSelectedImage('/images/image_1.jpeg')} className="owl-dot">
                                            <img style={{width:'100%', height:'165px'}} src="/images/image_1.jpeg" alt="1" />
                                            <h5 className="overlay-feature-title">
                                            </h5>
                                        </div>

                                        <div onClick={()=>setSelectedImage("/images/image_2.jpeg")} className="owl-dot">
                                            <img style={{width:'100%', height:'165px'}} src="/images/image_2.jpeg" alt="2" />
                                            <h5 className="overlay-feature-title">
                                            </h5>
                                        </div>

                                        <div onClick={()=>setSelectedImage("/images/image_3.jpeg")} className="owl-dot">
                                            <img style={{width:'100%', height:'165px'}} src={"/images/image_3.jpeg"} alt="3" />
                                            <h5 className="overlay-feature-title">
                                            </h5>
                                        </div>

                                        <div onClick={()=>setSelectedImage("/images/image_4.jpeg")} className="owl-dot">
                                            <img style={{width:'100%', height:'165px'}} src={"/images/image_4.jpeg"} alt="4" />
                                            <h5 className="overlay-feature-title">
                                            </h5>
                                        </div>

                                        <div onClick={()=>setSelectedImage("/images/image_5.jpeg")} className="owl-dot">
                                            <img style={{width:'100%', height:'165px'}} src={"/images/image_5.jpeg"} alt="5" />
                                            <h5 className="overlay-feature-title">
                                            </h5>
                                        </div>

                                        <div onClick={()=>setSelectedImage("/images/image_6.jpeg")} className="owl-dot">
                                            <img style={{width:'100%', height:'165px'}} src={"/images/image_6.jpeg"} alt="6" />
                                            <h5 className="overlay-feature-title">
                                            </h5>
                                        </div>

                                        <div onClick={()=>setSelectedImage("/images/image_7.jpeg")} className="owl-dot">
                                            <img style={{width:'100%', height:'165px'}} src={"/images/image_7.jpeg"} alt="7" />
                                            <h5 className="overlay-feature-title">
                                            </h5>
                                        </div>

                                        <div onClick={()=>setSelectedImage("/images/image_8.jpeg")} className="owl-dot">
                                            <img style={{width:'100%', height:'165px'}} src={"/images/image_8.jpeg"} alt="8" />
                                            <h5 className="overlay-feature-title">
                                            </h5>
                                        </div>

                                        <div onClick={()=>setSelectedImage("/images/image_9.jpeg")} className="owl-dot active">
                                            <img style={{width:'100%', height:'165px'}} src={"/images/image_9.jpeg"} alt="9" />
                                            <h5 className="overlay-feature-title">
                                            </h5>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-5 col-sm-12 col-xs-12 nopadding">
                            <div id="feature-left" className="menu-carousel owl-carousel image-carousel feature-left custom-js owl-loaded" data-animate-in="fadeIn" data-margin="0" data-nav="false" data-pagi="false" data-items="1">
                                <div className="cl-ft-item">
                                    <img src={selectedImage} alt="" />
                                    
                                    <div className="feature-content clearfix" style={{padding:'0px'}}>
                                        <div className="heading-block">
                                            <h4 className="feature-title">
                                                Student Life at NIPRM
                                            </h4>
                                            <p>Developing healthcare professionals with world class profile in research, education, and patient care in the field of Physiotherapy</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default LifeAtCampus
