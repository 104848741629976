import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Marquee from 'react-fast-marquee'

function Tickers() {
  
  const [announcements,setAnnouncements] = useState([ { id:'', title: '', File: '', Date: '', createdAt: '', updatedAt: ''} ])
  
  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL+'/announcements').then(response=>setAnnouncements(response.data.data)).catch(error=>console.log(error))
  }, [])

  return (
    <a href='/newsandevents/announcements'>
      <div className="tickers">
        <Marquee speed={60} gradient={false} pauseOnHover>
          {
            announcements.map((announcement,index)=>{
              return(
                <div key={index} className="tickers-text text1">
                  <span>&#x2022; {announcement.title}</span>
                </div>
              )
            })
          }
        </Marquee>
      </div>
    </a>
  )
}

export default Tickers
