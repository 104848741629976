import React, { useEffect, useState } from 'react'
import Breadcumbs from '../../components/Breadcumbs'
import Footer from '../../components/Footer'
import ImageHeader from '../../components/ImageHeader'
import Navigationbar from '../../components/Navigationbar'
// import banner_8 from '../../images/banner_8.png'
import { Accordion,Container } from 'react-bootstrap'
import axios from 'axios'

const ExamSchedule = () => {
    const items = [{item:'Home',url:'/'}]
    const itemActive = "Academics / Student's Exam Schedules"

    const constructData = () => {
        const unique = [...new Set(examSchedules.map(item => item.semester))]; // [ 'A', 'B']
        let arr = []
        unique.forEach(semester => {
            let array = []
            examSchedules.forEach(schedule=>{
                if(schedule.semester === semester){
                    array.push(schedule)
                }
            })
            arr.push(array)
        });
        return arr;
    };

    const [examSchedules, setExamSchedules] = useState([{id: '',semester: '',esFile: '',createdAt: '',updatedAt: ''}])
    
    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL+'/examschedules').then(response=>setExamSchedules(response.data.data)).catch(error=>console.log(error))    
    },[])

    let [data] = useState([]) 
    data = constructData();
    // console.log(data);

    return (
        <div>
            <Navigationbar></Navigationbar>
            <ImageHeader image={"/images/banner_8.png"}></ImageHeader>
            <Breadcumbs items={items} actives={itemActive}></Breadcumbs>
            <br/>
            <Container>
                <h2>Exam Schedule</h2>
                <Accordion>
                    {
                        data.map((semester,index)=>{
                            return (
                                <Accordion.Item eventKey={index}>
                                    <Accordion.Header>{semester[0].semester}</Accordion.Header>
                                    <Accordion.Body>
                                    {
                                        semester.map((schedule,ind)=>{
                                            return (
                                                <a className="btn btn-secondary" style={{margin:'10px 10px', background:'rgb(55 8 131)'}} href={process.env.REACT_APP_API_URL+'/'+schedule.esFile}>{schedule.esFile.substring(schedule.esFile.indexOf("_") + 1)}</a>
                                            )
                                        })
                                    }
                                    </Accordion.Body>
                                </Accordion.Item>    
                            )
                        })
                    }
                </Accordion>
            </Container>
            <br/>
            <Footer></Footer>
        </div>
    )
}

export default ExamSchedule
