import React, { useState, useEffect } from 'react'
import Breadcumbs from '../components/Breadcumbs'
import Footer from '../components/Footer'
import ImageHeader from '../components/ImageHeader'
import Navigationbar from '../components/Navigationbar'
import { Row,Container } from 'react-bootstrap'
import ImageSlider from '../components/ImageSlider'
import axios from 'axios'


const Facilities = () => {
    const items = [{item:'Home',url:'/'}]
    const itemActive = 'Facilities'
    
    const [facilities, setfacilities] = useState([{createdAt: "", name: "", images: [], id: '', updatedAt: ""}])

	useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL+'/facilities')
        .then(response=>{
            const array = []
            const res = response.data.data
            for (let i = 0; i < res.length; i++) {
                const element = res[i];
                let facility = {id: element.id, name: element.name, images: [], createdAt: element.createdAt, updatedAt: element.updatedAt}
                let images = element.images.split(";")
                for (let j = 0; j < images.length; j++) {
                    const img = images[j];
                    facility.images.push({ largeURL: process.env.REACT_APP_API_URL+'/'+img, thumbnailURL: process.env.REACT_APP_API_URL+'/'+img, width: 4032, height: 3024, width_thumb: 500, height_thumb: 375 })
                }
                array.push(facility)
            }
            setfacilities(array)
        })
        .catch(error=>console.log(error))
    }, [])

    return (
        <div>
            <Navigationbar></Navigationbar>
            <ImageHeader image={"/images/banner_11.png"}></ImageHeader>
            <Breadcumbs items={items} actives={itemActive}></Breadcumbs>
            <br/>
            <Container style={{maxWidth: '1600px'}}>
                {/* <h3>labs,library,opd,gym</h3> */}
                {
                    facilities.map((facility, index)=>{
                        return(
                            <Row key={index}>
                                <h3>{facility.name}<br/></h3>
                                <hr style={{margin: '0',background: '#3402fc',borderWidth: '5px'}}/>
                                <ImageSlider
                                    galleryID={"modern--gallery-"+facility.id}
                                    images={facility.images}
                                />
                            </Row>
                        )
                    })
                }
                
                {/* <Row>
                    <h3>Modern Labs<br/></h3>
					<hr style={{margin: '0',background: '#3402fc',borderWidth: '5px'}}/>
                    <ImageSlider
                        galleryID="modern-labs-gallery"
                        images={[ 
                            { largeURL: "/images/Lab1.jpg", thumbnailURL: "/images/Lab1.jpg", width: 4032, height: 3024, width_thumb: 500, height_thumb: 375 },
                            { largeURL: "/images/Lab2.jpg", thumbnailURL: "/images/Lab2.jpg", width: 4032, height: 3024, width_thumb: 500, height_thumb: 375 },
                            { largeURL: "/images/Lab3.jpg", thumbnailURL: "/images/Lab3.jpg", width: 4032, height: 3024, width_thumb: 500, height_thumb: 375 },
                        ]}
                    />
                </Row>
                <Row>
                    <h3>Library<br/></h3>
					<hr style={{margin: '0',background: '#3402fc',borderWidth: '5px'}}/>
                    <ImageSlider
                        galleryID="modern-labs-gallery"
                        images={[
                            { largeURL: "/images/library1.jpg", thumbnailURL: "/images/library1.jpg", width: 4032, height: 3024, width_thumb: 500, height_thumb: 375 },
                            { largeURL: "/images/library2.jpg", thumbnailURL: "/images/library2.jpg", width: 4032, height: 3024, width_thumb: 500, height_thumb: 375 },
                            { largeURL: "/images/library3.jpg", thumbnailURL: "/images/library3.jpg", width: 4032, height: 3024, width_thumb: 500, height_thumb: 375 },
                        ]}
                    />
                </Row>
                <Row>
                    <h3>GYM<br/></h3>
					<hr style={{margin: '0',background: '#3402fc',borderWidth: '5px'}}/>
                    <ImageSlider
                        galleryID="modern-labs-gallery"
                        images={[
                            { largeURL: "/images/GYM1.jpg", thumbnailURL: "/images/GYM1.jpg", width: 4032, height: 3024, width_thumb: 500, height_thumb: 375 },
                            { largeURL: "/images/GYM2.jpg", thumbnailURL: "/images/GYM2.jpg", width: 4032, height: 3024, width_thumb: 500, height_thumb: 375 },
                            { largeURL: "/images/GYM3.jpg", thumbnailURL: "/images/GYM3.jpg", width: 4032, height: 3024, width_thumb: 500, height_thumb: 375 },
                        ]}
                    />
                </Row>
                <Row>
                    <h3>OPD<br/></h3>
					<hr style={{margin: '0',background: '#3402fc',borderWidth: '5px'}}/>
                    <ImageSlider
                        galleryID="modern-labs-gallery"
                        images={[
                            { largeURL: "/images/OPD1.jpg", thumbnailURL: "/images/OPD1.jpg", width: 4032, height: 3024, width_thumb: 500, height_thumb: 375 },
                            { largeURL: "/images/OPD2.jpg", thumbnailURL: "/images/OPD2.jpg", width: 4032, height: 3024, width_thumb: 500, height_thumb: 375 },
                            { largeURL: "/images/OPD3.jpg", thumbnailURL: "/images/OPD3.jpg", width: 4032, height: 3024, width_thumb: 500, height_thumb: 375 },
                        ]}
                    />
                </Row> */}
                <br/>
               
            </Container>
            <br />
            <br />
            <Footer></Footer>
        </div>
    )
}

export default Facilities
