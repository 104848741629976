import React from 'react'
import { Container,Breadcrumb } from 'react-bootstrap'

const Breadcumbs = (props) => {
    
    return (
        <Container style={{margin: '10px 50px'}}>
            <Breadcrumb>
                {
                    props.items.map((element, index) => (
                        <Breadcrumb.Item key={index} href={element.url}>{element.item}</Breadcrumb.Item>
                    ))
                }
                <Breadcrumb.Item active>{props.actives}</Breadcrumb.Item>
            </Breadcrumb>
        </Container>
    )
}

export default Breadcumbs
