import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'

import Navigationbar from '../components/Navigationbar'
import ImageHeader from '../components/ImageHeader'
import Breadcumbs from '../components/Breadcumbs'
import Footer from '../components/Footer'

import { Nav,Row,Col,Tab,Container } from 'react-bootstrap'

function Hospitals() {
    
    const { hospital } = useParams()

    const items = [{item:'Home',url:'/'}]
    const itemActive = 'Hospitals / '+hospital
    
    const [hospitals,setHospitals] = useState([ { id:'', name: '', description: '', createdAt: '', updatedAt: ''} ])
    const [selectedHospital,setSelectedHospital] = useState(-1)
  
    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL+'/hospitals')
        .then((response) => {
            setHospitals(response.data.data)
            // console.log(hospitals)
            // console.log(hospital)
            const selected = hospitals.find(hosp=> hosp.name === hospital)
            // console.log(selected)
            setSelectedHospital(selected.id)
        })
        .catch((error)=>{
            console.log(error)
        })
    }, [hospitals, hospital])
    
    // console.log(selectedHospital)

    return (
        <div>
            <Navigationbar></Navigationbar>
            <ImageHeader image={"/images/banner_18.png"}></ImageHeader>
            <Breadcumbs items={items} actives={itemActive}></Breadcumbs>
            <br/>
            
            <Container>
                <Tab.Container id="left-tabs-example" activeKey={selectedHospital} defaultActiveKey={selectedHospital}>
                    <Row>
                        
                        <Col sm={3}>
                            <h3>Quick links</h3>
                            <Nav variant="pills" className="flex-column">
                            {
                                hospitals.map((hospital,index)=>{
                                    return(
                                        <Nav.Item>
                                            <Nav.Link href={`/hospitals/${hospital.name}`} eventKey={hospital.id}>{hospital.name}</Nav.Link>
                                        </Nav.Item>
                                    )
                                })
                            }
                            </Nav>
                        </Col>

                        <Col sm={9}>
                            <Tab.Content>
                                {
                                    hospitals.map((hospital,index)=>{
                                        return(
                                            <Tab.Pane eventKey={hospital.id}>
                                                <div dangerouslySetInnerHTML={{__html: hospital.description}}></div>
                                            </Tab.Pane>
                                        )
                                    })
                                }
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>
           
           
            <br />
            <Footer></Footer>
        </div>
    )
}

export default Hospitals