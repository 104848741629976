import React, { useEffect, useState } from 'react';
import Breadcumbs from '../../components/Breadcumbs';
import Footer from '../../components/Footer';
import ImageHeader from '../../components/ImageHeader';
import Navigationbar from '../../components/Navigationbar';
// import banner_12 from '../../images/banner_12.png';
import axios from 'axios';
import { Accordion } from 'react-bootstrap';

const HowToApply = () => {
    const items = [{item:'Home',url:'/'}]
    const itemActive = 'Admissions / How To Apply'

    const [admissions, setAdmissions] = useState({ adAppForm: "", adFormalitiesDate: "", adResultDate: "", adStatus: '', adTestDate: "", adTestFee: '', admissionFee: '', createdAt: "", id: '', lastDateToApply: '', securityDeposit: '', updatedAt: ""})

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL+'/admissions').then(response=>setAdmissions(response.data.data)).catch(error=>console.log(error))
    }, [])
    
    return (
        <div>
            <Navigationbar></Navigationbar>
            <ImageHeader image={"/images/banner_12.png"}></ImageHeader>
            <Breadcumbs items={items} actives={itemActive}></Breadcumbs>
            <br></br>
            <br></br>
            <div className="content">
                <div className="section section-padding">
                    <div className="container">
                        <h2>How To Apply For Addmission</h2>
                        <Accordion>
                            <Accordion.Item eventKey='0'>
                                <Accordion.Header>Instructions To Apply For Admission</Accordion.Header>
                                <Accordion.Body>
                                    <ul style={{listStyleType: 'circle',marginLeft:'10px',fontSize:'14px'}}>
                                        {
                                            admissions.adAppForm?
                                            <li>Download the latest Application Form from here, Once the Admissions are open.</li>
                                            :<li>Collect the latest Application Form from the Campus, Once the Admissions are open.</li>
                                        }
                                        <li>Application Form duly filled for admission must be submitted at the National Institute of Physiotherapy and Rehabilitation Medicine with attested copies of the following documents.
                                            <ol style={{marginLeft:'10px'}}>
                                                <li>Photocopy of Deposit Slip of <strong>Rs. {admissions.adTestFee}/= </strong> only deposited in HBL Qasimabad Branch.</li>
                                                <li>Six Photocopies of Secondary School Pass Certificate (Matriculation) or O-Level Equivalence Certificate by IBCC.</li>
                                                <li>Six Photocopies of Secondary School Marks Certificate (Matriculation) or O-Level Equivalence Certificate by IBCC.</li>
                                                <li>Six Photocopies of Higher Secondary Provisional Pass Certificate (Intermediate) or A-Level Equivalence Certificate by IBCC.</li>
                                                <li>Six Photocopies of Higher Secondary Provisional Marks Certificate (Intermediate) or A-Level Grade Statement Certificate by Board.</li>
                                                <li>Six Photocopies of Candidate's Domicile relevant from any Province of Pakistan.</li>
                                                <li>Six Photocopies of Candidate's Permanent Residence Certificate (PRC Form 'C') from any Province of Pakistan.</li>
                                                <li>Six Photocopies of Domicile Certificate on (Form P-I) Father / Guardian from any Province of Pakistan.</li>
                                                <li>In case Father is not alive, Death Certificate of Father (Three Photocopies).</li>
                                                <li>Three Photocopies of Candidate's CNIC or "B" Form (If a candidate is below the age of 18 years).</li>
                                                <li>Six Photocopies of CNIC of Father / Guardian.</li>
                                                <li>Six Passport size Photograph of Candidate.</li>
                                            </ol>
                                        </li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey='1'>
                                <Accordion.Header>Important Notes</Accordion.Header>
                                <Accordion.Body>
                                    <ul style={{listStyleType: 'circle',marginLeft:'10px',fontSize:'14px'}}>
                                        <li>Bring all the original documents at the time of Test/Interview and such original Academic/Residential documents will be collected from those candidates who finally have been selected/admitted in the five years Degree Program of Doctor of Physiotherapy.</li>
                                        <li>The Original Certificate of the selected candidate shall be retained for the period up to verification of his/her document.</li>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>                     
                    </div>    
                </div>
		    </div>
            <br/><br/><br/>
            <Footer></Footer>
        </div>
    )
}

export default HowToApply
