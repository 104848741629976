import React, { useEffect } from 'react';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import Carousel from 'react-multi-carousel';
import 'photoswipe/style.css';
import 'react-multi-carousel/lib/styles.css';
import { Card } from 'react-bootstrap';

const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
};

export default function EventCard({galleryID, events, width, height}) {
    
    useEffect(() => {
        let lightbox = new PhotoSwipeLightbox({
          gallery: '#' + galleryID,
          children: 'a',
          pswpModule: () => import('photoswipe'),
        });
        lightbox.init();
    
        return () => {
          lightbox.destroy();
          lightbox = null;
        };
    }, [galleryID]);
    
    return (
        <div className="pswp-gallery" id={galleryID} style={{display: 'ruby'}}>
        {
            events.map((event,index)=>{
                return(
                        <Card style={{ width: width, margin: '10px', borderRadius: '20px', boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px" }}>
                            <Carousel 
                                swipeable={true}
                                draggable={false}
                                responsive={responsive}
                                showDots={true}
                                ssr={true} // means to render carousel on server-side.
                                // infinite={true}
                                autoPlaySpeed={1000}
                                keyBoardControl={true}
                                customTransition="all .5"
                                transitionDuration={500}
                                containerClass="carousel-container"
                                removeArrowOnDeviceType={["tablet", "mobile"]}
                                dotListClass="custom-dot-list-style"
                                itemClass="carousel-item-padding-40-px"
                            >
                                {event.eventImages.map((image, index) => (
                                    <a
                                        href={image.largeURL}
                                        data-pswp-width={image.width}
                                        data-pswp-height={image.height}
                                        key={galleryID + '-' +event.title+ index}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img src={image.thumbnailURL} style={{width:image.width_thumb, height:image.height_thumb}} alt="" />
                                    </a>
                                ))}
                            </Carousel>
                            <Card.Body>
                                <Card.Title>{event.title}</Card.Title>
                                <Card.Text>{event.eventDescription}</Card.Text>
                            </Card.Body>
                            <Card.Footer>
                                <small className="text-muted">Event Occured on {event.dateOccurred.substring(0, 10)}</small>
                            </Card.Footer>
                        </Card>
                    )
                })
            }
            </div>
    )
}