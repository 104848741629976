import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'

import Navigationbar from '../components/Navigationbar'
import ImageHeader from '../components/ImageHeader'
import Breadcumbs from '../components/Breadcumbs'
import Footer from '../components/Footer'

import { Nav,Row,Col,Tab,Container } from 'react-bootstrap'

function Departments() {
    
    const { dept } = useParams()

    const items = [{item:'Home',url:'/'}]
    const itemActive = 'Departments / '+dept
    
    const [departments,setDepartments] = useState([ { id:'', name: '', description: '', createdAt: '', updatedAt: ''} ])
    const [selectedDepartment,setSelectedDepartment] = useState(-1)
  
    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL+'/departments')
        .then((response) => {
            setDepartments(response.data.data)
            // console.log(departments)
            // console.log(dept)
            const selected = departments.find(department=> department.name === dept)
            // console.log(selected)
            setSelectedDepartment(selected.id)
        })
        .catch((error)=>{
            console.log(error)
        })
    }, [departments, dept])
    
    // console.log(selectedDepartment)
    return (
        <div>
            <Navigationbar></Navigationbar>
            <ImageHeader image={"/images/banner_17.png"}></ImageHeader>
            <Breadcumbs items={items} actives={itemActive}></Breadcumbs>
            <br/>
            
            
            <Container>
                <Tab.Container id="left-tabs-example" activeKey={selectedDepartment} defaultActiveKey={selectedDepartment}>
                    <Row>
                        
                        <Col sm={3}>
                            <h3>Quick links</h3>
                            <Nav variant="pills" className="flex-column">
                            {
                                departments.map((department,index)=>{
                                    return(
                                        <Nav.Item>
                                            <Nav.Link href={`/departments/${department.name}`} eventKey={department.id}>{department.name}</Nav.Link>
                                        </Nav.Item>
                                    )
                                })
                            }
                            </Nav>
                        </Col>

                        <Col sm={9}>
                            <Tab.Content>
                                {
                                    departments.map((department,index)=>{
                                        return(
                                            <Tab.Pane eventKey={department.id}>
                                                <div dangerouslySetInnerHTML={{__html: department.description}}></div>
                                            </Tab.Pane>
                                        )
                                    })
                                }
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </Container>
           
            <br />
            <Footer></Footer>
        </div>
    )
}

export default Departments