import React, { useState, useEffect } from 'react'
import Breadcumbs from '../components/Breadcumbs'
import Footer from '../components/Footer'
import ImageHeader from '../components/ImageHeader'
import Navigationbar from '../components/Navigationbar'
// import banner_9 from '../images/banner_9.png'
import { Card,Container } from 'react-bootstrap'
import axios from 'axios'

const Faculty = () => {
    const items = [{item:'Home',url:'/'}]
    const itemActive = 'Faculty'

    const [faculty, setFaculty] = useState([{createdAt: "", department: "", email: "", id: '', image: "", name: "", position: "", updatedAt: ""}])
	// console.log(faculty)

	useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL+'/faculty').then(response=>setFaculty(response.data.data)).catch(error=>console.log(error))
    }, [])

    return (
        <div>
            <Navigationbar></Navigationbar>
            <ImageHeader image={"images/banner_9.png"}></ImageHeader>
            <Breadcumbs items={items} actives={itemActive}></Breadcumbs>
            <br/>
            <Container>
                <div className="row" style={{padding:'10px 10px'}}>
                    {
                        faculty.map((fac,index)=>{
                            return(
                                <div className="col-lg-3 col-md-6 col-sm-12" style={{padding:'5px 10px 20px 10px'}}>
                                    <Card style={{boxShadow: '0px 0px 10px 5px whitesmoke', paddingLeft:'0px',paddingRight:'0px',background:'white',textAlign: 'left', fontFamily:'emoji'}}> 
                                        <Card.Img variant="top" src={process.env.REACT_APP_API_URL+'/'+fac.image} style={{width:'350px',height:'250px',padding:'0px 25px'}}/>
                                        <Card.Body>
                                            <Card.Title style={{color:'lightslategrey'}}><span style={{color:"rgb(20,6,43)"}}>Name: </span> {fac.name}</Card.Title>
                                            <Card.Title style={{color:'lightslategrey'}}><span style={{color:"rgb(20,6,43)"}}>Email: </span> {fac.email}</Card.Title>
                                            <Card.Title style={{color:'lightslategrey'}}><span style={{color:"rgb(20,6,43)"}}>Position: </span> {fac.position}</Card.Title>
                                            <Card.Title style={{color:'lightslategrey'}}><span style={{color:"rgb(20,6,43)"}}>Department: </span> {fac.department}</Card.Title>
                                        </Card.Body>
                                    </Card>
                                </div>
                            )
                        })
                    }
                </div>
            </Container>
            <br/>
            <Footer></Footer>
        </div>
    )
}

export default Faculty
