import React from 'react'

const ChooseUs = () => {
    
    return (
        // <!-- Expertise Area satar -->
        <div className="why-choose-us" style={{background:'#f2f2f2'}}>    
            {/* <!-- Counter Up Section Start Here--> */}
            <div className="counter-top-area defult-style">
                <div className="container">
                    <div className="row">
                        <h3 className="text-center text-jsmu">Why Choose Us</h3>
                        
                        <div className="rs-count" style={{display:'flex', flexWrap:'wrap'}}>
                            
                            <div className="col-md-3 col-sm-6 wow fadeInUp" data-wow-duration=".3s" data-wow-delay="300ms" style={{padding:'0px 10px',margin: "20px 0px"}}>
                                <div className="rs-counter-list">
                                    <p style={{cursor:'default'}} className='Nav-link'><span className="service-mid-icon-container"><i className="fa fa-graduation-cap"></i></span></p>
                                    <h6 className="text-jsmu">Highly Qualified Faculty</h6>
                                    <p className="text-justify">Recognized for the quality of their scientific and teaching qualifications. The medical lecturers are all active hospital practitioners</p>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-6 wow fadeInUp" data-wow-duration="1.2s" data-wow-delay="300ms" style={{padding:'0px 10px',margin: "20px 0px"}}>
                                <div className="rs-counter-list">
                                    <p style={{cursor:'default'}} className='Nav-link'><span className="service-mid-icon-container"><i className="fa fa-desktop"></i></span></p>
                                    <h6 className="text-jsmu">Libraries & Labs</h6>
                                    <p className="text-justify">Students have access to International Research Journals and modern Anatomy, Physiology and Medical Physics labs </p>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-6 wow fadeInUp" data-wow-duration=".9s" data-wow-delay="300ms" style={{padding:'0px 10px',margin: "20px 0px"}}>
                                <div className="rs-counter-list">
                                    <p style={{cursor:'default'}} className='Nav-link'><span className="service-mid-icon-container"><i className="fa fa-book"></i></span></p>
                                    <h6 className="text-jsmu">Physiotherapy OPD</h6>
                                    <p className="text-justify">Students get chance to learn in OPD while studying at NIPRM.</p>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-6 wow fadeInUp" data-wow-duration=".7s" data-wow-delay="300ms" style={{padding:'0px 10px',margin: "20px 0px"}}>
                                <div className="rs-counter-list">
                                    <p style={{cursor:'default'}} className='Nav-link'><span className="service-mid-icon-container"><i className="fa fa-wifi"></i></span></p>
                                    <h6 className="text-jsmu">Centralized Wifi</h6>
                                    <p className="text-justify">Providing internet facility connecting All Labs and staff at NIPRM.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChooseUs
