import React, { useState, useEffect} from 'react'
import axios from 'axios'

const VCMessage = () => {
    const [principalMsg,setPrincipalMsg] = useState([
        {
            Message: "",
            createdAt: "",
            id: "",
            principalImage: "",
            principalName: "",
            updatedAt: ""
        }
    ])
    
    useEffect(() => {
        axios.get(process.env.REACT_APP_API_URL+'/principalmsg').then(response=>{
            // console.log(response.data.data)
            setPrincipalMsg(response.data.data)
        }).catch(error=>{
            console.log(error)
        })
    }, [])

    return (
        <div>
            {/* <!-- About Us Start --> */}
            <section id="rs-about" className="rs-about pt-60 pb-40">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-sm-12 pr-md-65">
                            <div className="sec-title">
                                <h3>Principal's Message</h3>
                            </div>
                            <div dangerouslySetInnerHTML={{__html: principalMsg.Message}}></div>
                            
                            <div className="signature-text">
                                <span className="sign-subtitle">Wishing you all good luck</span>
                                <br/>
                                <span className="sign-subtitle">With prayers,</span>
                                <p className="sign-title">{principalMsg.principalName}</p>
                                <span className="sign-subtitle">Principal (NIPRM)</span>
                                {/* <!-- <img src="assets/images/about/signature.png" alt=""> --> */}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 hidden-xs hidden-sm">
                            <div className="about-img" style={{animation: "3s slide-right"}}>
                                <img style={{height:'550px'}} src={process.env.REACT_APP_API_URL+'/'+principalMsg.principalImage} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- About Us End --> */}
        </div>
    )
}

export default VCMessage
